<template>
    <v-card class="brand_gift_card">
        <v-row no-gutters>
            <v-col cols="3" class="pa-2" @click="detailEvent">
                <v-img :src="row.product_img"
                       height="70px"
                       width="70px"
                       style="cursor: pointer"/>
            </v-col>

            <v-col cols="7" class="align-self-center" @click="detailEvent">
                <h6 class="gift_title font-weight-medium">
                    {{ row.product_name }}
                </h6>
                <h6 class="org_price font-weight-thin grey--text">
                    {{ formatPrice(row.org_price) }}
                </h6>
                <h6 class="sale_price font-weight-medium">
                    {{ formatPrice(row.sale_price) }}
                </h6>
            </v-col>

            <v-col cols="2" class="align-self-center" style="text-align: -webkit-center">
                <v-img :src="row.agency"
                       height="23px"
                       width="23px"
                       style="cursor: pointer"/>

                <v-btn icon
                       small
                       :color="row.favorite==true?'#ff0000': 'grey'"
                       @click="updateFavorite(row)"
                >
                    <v-icon>favorite</v-icon>
                </v-btn>
            </v-col>
        </v-row>

    </v-card>
</template>

<script>
    export default {
        name: "giftcard",
        props: {
            row: {
                type: Object,
                require: false
            }
        },
        methods:{
            formatPrice(value) {
                try {
                    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+'원';
                }
                catch{
                    return value+'원';
                }
            },
            updateFavorite(row){
                this.$props.row.favorite = !row.favorite
            },
            detailEvent(){
                this.$emit('childs-event', this.$props.row);
            },
        }
    }
</script>

<style lang="scss">
    .gift_title{
        font-size: 0.875rem;
        letter-spacing: -0.05em;
    }
    .org_price{
        font-size: 0.7rem;
        text-decoration: line-through;
        letter-spacing: -0.03em;
    }
    .sale_price{
        font-size: 0.825rem;
        color: #5EB4F9;
        letter-spacing: -0.03em;
    }
    .brand_gift_card{
        border: .8px solid rgb(0 0 0 / 12%) !important;
    }
</style>

<style lang="scss" scoped>
    .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: none !important;
    }
</style>